import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import { rtdbPlugin } from 'vuefire'

import * as firebase from "firebase";

Vue.use(VueRouter)


const configOptions = {
  apiKey: "AIzaSyDl9lePGMc3aA5BlmoquHlSZeFt7iUk8yg",
  authDomain: "androidfirebase-f621e.firebaseapp.com",
  databaseURL: "https://androidfirebase-f621e.firebaseio.com",
  projectId: "androidfirebase-f621e",
  storageBucket: "androidfirebase-f621e.appspot.com",
  messagingSenderId: "249715425910",
};
firebase.initializeApp(configOptions);

export const fbase = firebase


//easy-session
import VueEasySession from "vue-easysession";
var options = {
  persist: true,
  keySession: "myKeySession",
  expireSessionCallback: function() {
    window.location.href = "/";
  },
};

Vue.use(VueEasySession.install, options);

Vue.config.productionTip = false
Vue.use(rtdbPlugin)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
