<template>
  <v-card class="mx-auto overflow-hidden" height="50">
    <v-app-bar color="grey darken-4" dark app>
      <v-app-bar-nav-icon
        v-if="this.$session.exists()"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>Hapkido Scoring</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" v-if="this.$session.exists()" app>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="grey darken-1--text text--accent-4"
        >
          <router-link
            v-for="route in routes"
            :key="route.path"
            :to="route.path"
            tag="p"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>{{ route.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ route.name }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn dark color="grey darken-4" block @click="logout()">
            <v-icon>mdi-logout</v-icon>Keluar
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  // Add the Firebase products that you want to use
  import firebase from 'firebase/app';
  import "firebase/auth";
  import "firebase/firestore";

  export default {
    name: 'Bars',

    data: () => ({
      drawer: false,
      group: null,
      routes: [
                // {path: 'user', name: 'Users', icon: 'mdi-account-multiple'},
                {path: 'final', name: 'Join Arena', icon: 'mdi-desktop-mac'},
                {path: 'score', name: 'Score', icon: 'mdi-scoreboard'},
              ]
    }),methods: {
      logout() {
        this.$session.destroy();
        this.$router.go('/login');

        firebase.auth().signOut();
      },
    },
  };
</script>