import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Home',
    component: Vue.component("page-not-found", {
      template: "",
      created: function() {
          window.location.href = "/login";
      }
    })
  },
  {
    path: '/score',
    name: 'Score',
    component: () => import( '../views/Score.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import( '../views/User.vue')
  },
  {
    path: '/final',
    name: 'Final',
    component: () => import( '../views/Final.vue')
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
});


export default router
